<template>
    <CRow>
        <CCol :md="3">
            <ResultsStats :typeRis="'1X2'" :labels="labels" :chartData="results" />
        </CCol>
        <CCol :md="3">
            <ResultsStats :typeRis="'BTTS'" :labels="labels" :chartData="bttsRes" />
            <ResultsStats :typeRis="'BTTN'" :labels="labels" :chart-data="bttnRes" />
        </CCol>
        <CCol :md="3">
            <ResultsStats :typeRis="'Under 2.5'" :labels="labels" :chartData="uRes" />
            <ResultsStats :typeRis="'Over 2.5'" :labels="labels" :chartData="oRes" />
        </CCol>
    </CRow>
    <datepicker v-model="datePick" />
    <ModalViewMatch v-if="openMatch && !openModalMatch" :eventid="eventidSet"  />
    <vue-good-table
    :columns="columns"
    :rows="rows"
    :search-options="{
        enabled: true
    }"
    :pagination-options="{
        enabled: true
    }"
    v-on:cell-click="onCellClick"
    styleClass="vgt-table striped bordered condensed"
    :row-style-class="rowStyleClassFn"
    v-on:row-mouseenter="onRowMouseover"
    v-on:row-mouseleave="onRowMouseleave"
    />
</template>

<script>
import { getData } from '../../plugins/fetch.js'
// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'

import { VueGoodTable } from 'vue-good-table-next';
import Datepicker from 'vue3-datepicker';
import ResultsStats from '../../components/ResultsStats.vue'
import ModalViewMatch from '../../components/ModalViewMatch.vue';

import { ref, watch, computed } from 'vue-demi';
import { useStore } from 'vuex';

export default {
    name:"AllData",
    components:{
        VueGoodTable,
        Datepicker,
        ResultsStats,
        ModalViewMatch
    },
    setup() {
        const store = useStore();
        const openModalMatch = computed(() => store.state.openModalMatch);
        const datePick = ref(new Date());
        const regex = /\//gm;
        const subst = `-`;
        const rows = ref([]);
        const qty = ref(100);
        const labels = ref([]);
        const results = ref([]);
        const bttsRes = ref([]);
        const bttnRes = ref([]);
        const WLnum = ref([0,0]);
        const Wbtts = ref(0);
        const Lbtts = ref(0);
        const uRes = ref([]);
        const oRes = ref([]);
        const columns = [
            {
                label: 'Data',
                field: 'dateTime',
                type: 'date',
                dateInputFormat: 'dd/MM/yyyy',
                dateOutputFormat: 'dd/MM/yyyy',
            },
            {
                label: 'Ora',
                field: 'ora',
                type: 'date',
                dateInputFormat: 'HH:mm',
                dateOutputFormat: 'HH:mm',
            },
            {
                label: 'Filtro',
                field: 'filtro',
                type: 'string'
            },
            {
                label: 'Match',
                field: 'match',
                type: 'string'
            },
            {
                label: 'Campionato',
                field: 'league',
                type: 'string'
            },
            {
                label: 'Eventid',
                field: 'eventid',
                type: 'string'
            },
            {
                label: 'HT',
                field: 'resultHT',
                type: 'string'
            },
            {
                label: 'FT',
                field: 'resultFT',
                type: 'string'
            },
            {
                label: 'W/L HT',
                field: 'WLht',
                type: 'string'
            },
            {
                label: 'W/L FT',
                field: 'WLft',
                type: 'string'
            },
            {
                label: 'BTTS',
                field: 'btts',
                type: 'string'
            },
            {
                label: 'BTTN',
                field: 'bttn',
                type: 'string'
            }
        ];
        watch(datePick, (date) => {
            rows.value.length = 0;
            labels.value.length = 0;
            results.value.length = 0;
            bttsRes.value.length = 0;
            bttnRes.value.length = 0;
            oRes.value.length = 0;
            uRes.value.length = 0;
            getData(`https://api.soccerstandings.online/get-filtered/${date.toLocaleDateString().replace(regex, subst)}/${qty.value}`)
            .then((data) => {
                let idel = 1;
                for (const index in data) {
                    if (Object.hasOwnProperty.call(data, index)) {
                        const el = data[index];
                        labels.value.push(el['match']);
                        getData(`https://api.soccerstandings.online/get-filters-results/${el['eventid']}`).then((data) => {
                            const risultati = data != null && data.updated.scores != null ? data.updated.scores : '';
                            const resHT = typeof risultati != 'string' ? risultati != undefined && (risultati['1'] != null || risultati['1'] != undefined) ? Object.values(risultati['1']).join(':') : '' : '';
                            const resFT = typeof risultati != 'string' ? risultati != undefined && (risultati['2'] != null || risultati['2'] != undefined) ? Object.values(risultati['2']).join(':') : '' : risultati;
                            const explHT = resHT != '' ? resHT.split(":") : null;
                            const explFT = resFT != '' ? resFT.split(":") : null;
                            const WLht = explHT != null ? explHT[0] == explHT[1] ? 'Lose' : 'Win': '-';
                            const WLft = explFT != null ? explFT[0] == explFT[1] ? 'Lose' : 'Win': '-';
                            WLft == 'Win' ? results.value.push(89) : WLft == 'Lose' ? results.value.push(38) : results.value.push(30);
                            const btts = explFT != null ? explFT[0] != '0' && explFT[1] != '0' ? "Win" : "Lose" : '';
                            const bttn = explFT != null ? (explFT[0] == '0' && explFT[1] != '0') || (explFT[0] != '0' && explFT[1] == '0') ||  (explFT[0] == '0' && explFT[1] == '0') ? "Win" : "Lose" : '';
                            btts == 'Win' ? (Wbtts.value++, bttsRes.value.push(89), bttnRes.value.push(38) ): btts == '-' || btts == '' ? null : (Lbtts.value++, bttsRes.value.push(38), bttnRes.value.push(89));
                            explFT != null ? (explFT[0]+explFT[1]) > 2.5 ? (oRes.value.push(89), uRes.value.push(38)) : (oRes.value.push(38), uRes.value.push(89)) : null;
                            const constructed = {
                                id: idel,
                                dateTime: el['date'],
                                ora: el['time'],
                                filtro: el['filter_name'],
                                match: el['match'],
                                league: el['league'][0],
                                eventid: el['eventid'],
                                resultHT: resHT,
                                resultFT: resFT,
                                WLht: WLht,
                                WLft: WLft,
                                btts: btts,
                                bttn: bttn
                            };
                            rows.value.push(constructed);
                        }).catch(error => {
                            getData(`https://api.soccerstandings.online/getEvent/${el['eventid']}`).then((data) => {
                                const risultati = data != null && data.results[0].scores != null ? data.results[0].scores : '';
                                const resHT = typeof risultati != 'string' ? risultati != undefined && (risultati['1'] != null || risultati['1'] != undefined) ? Object.values(risultati['1']).join(':') : '' : '';
                                const resFT = typeof risultati != 'string' ? risultati != undefined && (risultati['2'] != null || risultati['2'] != undefined) ? Object.values(risultati['2']).join(':') : '' : risultati;
                                const explHT = resHT != '' ? resHT.split(":") : null;
                                const explFT = resFT != '' ? resFT.split(":") : null;
                                const WLht = explHT != null ? explHT[0] == explHT[1] ? 'Lose' : 'Win': '-';
                                const WLft = explFT != null ? explFT[0] == explFT[1] ? 'Lose' : 'Win': '-';
                                WLft == 'Win' ? results.value.push(89) : WLft == 'Lose' ? results.value.push(38) : results.value.push(30);
                                const btts = explFT != null ? explFT[0] != '0' && explFT[1] != '0' ? "Win" : "Lose" : '';
                                const bttn = explFT != null ? (explFT[0] == '0' && explFT[1] != '0') || (explFT[0] != '0' && explFT[1] == '0') ||  (explFT[0] == '0' && explFT[1] == '0') ? "Win" : "Lose" : '';
                                btts == 'Win' ? (Wbtts.value++, bttsRes.value.push(89), bttnRes.value.push(38) ): btts == '-' || btts == '' ? null : (Lbtts.value++, bttsRes.value.push(38), bttnRes.value.push(89));
                                explFT != null ? (explFT[0]+explFT[1]) > 2.5 ? (oRes.value.push(89), uRes.value.push(38)) : (oRes.value.push(38), uRes.value.push(89)) : null;
                                const constructed = {
                                    id: idel,
                                    dateTime: el['date'],
                                    ora: el['time'],
                                    filtro: el['filter_name'],
                                    match: el['match'],
                                    league: el['league'][0],
                                    eventid: el['eventid'],
                                    resultHT: resHT,
                                    resultFT: resFT,
                                    WLht: WLht,
                                    WLft: WLft,
                                    btts: btts,
                                    bttn: bttn
                                };
                                rows.value.push(constructed);
                            })
                        })

                        idel++;
                    }
                }
            })
            WLnum.value[0] = results.value.reduce((total,x) => (x==89 ? total+1 : total), 0);
            WLnum.value[1] = results.value.reduce((total,x) => (x==38 ? total+1 : total), 0);
        })
        return {
            datePick,
            rows,
            columns,
            labels,
            results,
            WLnum,
            openModalMatch,
            Wbtts,
            Lbtts,
            bttsRes,
            bttnRes,
            oRes,
            uRes
        }
    },
    data: () => ({
        date: new Date(),
        data: [],
        qtys: 100,
        openMatch: false,
        eventidSet: '',
        mouseOnMatch: false
    }),
    mounted(){
        const regex = /\//gm;
        const subst = `-`;

        getData(`https://api.soccerstandings.online/get-filtered/${this.date.toLocaleDateString().replace(regex, subst)}/${this.qtys}`)
            .then((data) => {
                let idel = 1;
                this.rows.length = 0;
                this.labels.length = 0;
                this.results.length = 0;
                this.bttsRes.length = 0;
                this.bttnRes.length = 0;
                this.oRes.length = 0;
                this.uRes.length = 0;
                for (const index in data) {
                    if (Object.hasOwnProperty.call(data, index)) {
                        const el = data[index];
                        this.labels.push(el['match']);
                        getData(`https://api.soccerstandings.online/get-filters-results/${el['eventid']}`).then((data) => {
                            const risultati = data != null && data.updated.scores != null ? data.updated.scores : '';
                            const resHT = typeof risultati != 'string' ? risultati != undefined && (risultati['1'] != null || risultati['1'] != undefined) ? Object.values(risultati['1']).join(':') : '' : '';
                            const resFT = typeof risultati != 'string' ? risultati != undefined && (risultati['2'] != null || risultati['2'] != undefined) ? Object.values(risultati['2']).join(':') : '' : risultati;
                            const explHT = resHT != '' ? resHT.split(":") : null;
                            const explFT = resFT != '' ? resFT.split(":") : null;
                            const WLht = explHT != null ? explHT[0] == explHT[1] ? 'Lose' : 'Win': '-';
                            const WLft = explFT != null ? explFT[0] == explFT[1] ? 'Lose' : 'Win': '-';
                            WLft == 'Win' ? this.results.push(89) : WLft == 'Lose' ? this.results.push(38) : this.results.push(30);
                            const btts = explFT != null ? explFT[0] != '0' && explFT[1] != '0' ? "Win" : "Lose" : '';
                            const bttn = explFT != null ? (explFT[0] == '0' && explFT[1] != '0') || (explFT[0] != '0' && explFT[1] == '0') ||  (explFT[0] == '0' && explFT[1] == '0') ? "Win" : "Lose" : '';
                            btts == 'Win' ? (this.Wbtts.value++, this.bttsRes.value.push(89), this.bttnRes.value.push(38) ): btts == '-' || btts == '' ? null : (this.Lbtts.value++, this.bttsRes.value.push(38), this.bttnRes.value.push(89));
                            explFT != null ? (explFT[0]+explFT[1]) > 2.5 ? (this.oRes.value.push(89), this.uRes.value.push(38)) : (this.oRes.value.push(38), this.uRes.value.push(89)) : null;
                            const constructed = {
                                id: idel,
                                dateTime: el['date'],
                                ora: el['time'],
                                filtro: el['filter_name'],
                                match: el['match'],
                                league: el['league'][0],
                                eventid: el['eventid'],
                                resultHT: resHT,
                                resultFT: resFT,
                                WLht: WLht,
                                WLft: WLft,
                                btts: btts,
                                bttn: bttn
                            };
                            this.rows.push(constructed);
                        }).catch(error => {
                            getData(`https://api.soccerstandings.online/getEvent/${el['eventid']}`).then((data) => {
                                const risultati = data != null && data.results[0].scores != null ? data.results[0].scores : '';
                                const resHT = typeof risultati != 'string' ? risultati != undefined && (risultati['1'] != null || risultati['1'] != undefined) ? Object.values(risultati['1']).join(':') : '' : '';
                                const resFT = typeof risultati != 'string' ? risultati != undefined && (risultati['2'] != null || risultati['2'] != undefined) ? Object.values(risultati['2']).join(':') : '' : risultati;
                                const explHT = resHT != '' ? resHT.split(":") : null;
                                const explFT = resFT != '' ? resFT.split(":") : null;
                                const WLht = explHT != null ? explHT[0] == explHT[1] ? 'Lose' : 'Win': '-';
                                const WLft = explFT != null ? explFT[0] == explFT[1] ? 'Lose' : 'Win': '-';
                                WLft == 'Win' ? this.results.push(89) : WLft == 'Lose' ? this.results.push(38) : this.results.push(30);
                                const btts = explFT != null ? explFT[0] != '0' && explFT[1] != '0' ? "Win" : "Lose" : '';
                                const bttn = explFT != null ? (explFT[0] == '0' && explFT[1] != '0') || (explFT[0] != '0' && explFT[1] == '0') ||  (explFT[0] == '0' && explFT[1] == '0') ? "Win" : "Lose" : '';
                                btts == 'Win' ? (this.Wbtts.value++, this.bttsRes.value.push(89), this.bttnRes.value.push(38) ): btts == '-' || btts == '' ? null : (this.Lbtts.value++, this.bttsRes.value.push(38), this.bttnRes.value.push(89));
                                explFT != null ? (explFT[0]+explFT[1]) > 2.5 ? (this.oRes.value.push(89), this.uRes.value.push(38)) : (this.oRes.value.push(38), this.uRes.value.push(89)) : null;
                                const constructed = {
                                    id: idel,
                                    dateTime: el['date'],
                                    ora: el['time'],
                                    filtro: el['filter_name'],
                                    match: el['match'],
                                    league: el['league'][0],
                                    eventid: el['eventid'],
                                    resultHT: resHT,
                                    resultFT: resFT,
                                    WLht: WLht,
                                    WLft: WLft,
                                    btts: btts,
                                    bttn: bttn
                                };
                                this.rows.push(constructed);
                            })
                        })
                        idel++;
                    }
                }

                this.WLnum[0] = this.results.reduce((total,x) => (x==89 ? total+1 : total), 0);
                this.WLnum[1] = this.results.reduce((total,x) => (x==38 ? total+1 : total), 0);
            })
    },
    methods:{
        clickHandle(params){
            console.log(params);
        },
        onCellClick(params) {
            if( params.column.field == 'match' ){
                this.openMatch = true;
                this.eventidSet = params.row.eventid;
            }
            // params.row - row object
            // params.column - column object
            // params.rowIndex - index of this row on the current page.
            // params.event - click event
        },
        rowStyleClassFn() {
            return 'pointed';
        },
        onRowMouseover() {
            this.mouseOnMatch = true;
        },
        onRowMouseleave() {
            this.mouseOnMatch = false;
            // row - row object
            // pageIndex - index of this row on the current page.
        }
    }
}
</script>

<style>
tr.pointed:hover {
    cursor: pointer;
    background-color: rgba(64, 146, 228, 0.472) !important;
    transition: 0.2s ease-in-out;
}
</style>