<template>
  <CWidgetStatsA class="mb-4" color="primary">
    <template #value
      >
      <h5>{{typeRis}}</h5>
      <h7 class="wlR">Win: {{ (chartData.filter((el) => el==89)).length }} | Lose {{ (chartData.filter((el) => el==38)).length }}</h7>
      <span class="fs-6 fw-normal">
        (W {{ (((chartData.filter((el) => el==89)).length / ((chartData.filter((el) => el==89)).length + (chartData.filter((el) => el==38)).length)) * 100).toFixed(2) }}% | L {{(((chartData.filter((el) => el==38)).length / ((chartData.filter((el) => el==89)).length + (chartData.filter((el) => el==38)).length)) * 100).toFixed(2)}}%)
      </span>
    </template>
    <template #action>
      <CDropdown placement="bottom-end">
        <CDropdownToggle
          color="transparent"
          class="p-0 text-white"
          :caret="false"
        >
          <CIcon icon="cil-options" class="text-high-emphasis-inverse" />
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem href="#">Action</CDropdownItem>
          <CDropdownItem href="#">Another action</CDropdownItem>
          <CDropdownItem href="#">Something else here</CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </template>
    <template #chart v-if="false">
      <CChart
        type="line"
        class="mt-3 mx-3"
        style="height: 70px"
        :data="{
          labels: labels,
          datasets: [
            {
              label: 'Risultato',
              backgroundColor: 'transparent',
              borderColor: 'rgba(255,255,255,.55)',
              pointBackgroundColor: '#321fdb',
              data: chartData,
            },
          ],
        }"
        :options="{
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              min: 30,
              max: 89,
              display: false,
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          },
          elements: {
            line: {
              borderWidth: 1,
              tension: 0.4,
            },
            point: {
              radius: 4,
              hitRadius: 10,
              hoverRadius: 4,
            },
          },
        }"
      />
    </template>
  </CWidgetStatsA>
</template>

<script>
import { CChart } from '@coreui/vue-chartjs'

export default {
  name: 'ResultsStats',
  props: {
    labels: { type: Object, default: new Array() },
    chartData: {type: Object, default: new Array()},
    typeRis: {type: String, default: ''}
  },
  components: {
    CChart
  },
  data(){
    return {
      Wnum: (this.chartData.filter((el) => el==89)).length,
      Lnum: (this.chartData.filter((el) => el==38)).length
    }
  }
}
</script>

<style>
.wlR{
  font-size: small;
}
</style>