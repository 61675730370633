<template>
  <CButton color="primary" @click="() => { visibleVerticallyCenteredScrollableDemo = true }">Vertically centered scrollable modal</CButton>
  <CModal alignment="center" scrollable :visible="visibleVerticallyCenteredScrollableDemo" @close="() => { visibleVerticallyCenteredScrollableDemo = false }">
    <CModalHeader>
      <CModalTitle>Modal title</CModalTitle>
    </CModalHeader>
    <CModalBody>
      Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="() => { visibleVerticallyCenteredScrollableDemo = false }">
        Close
      </CButton>
      <CButton color="primary">Save changes</CButton>
    </CModalFooter>
  </CModal>
</template>
<script>
import { useStore } from "vuex";
import { watch } from 'vue-demi';
  export default {
    props:{
      eventid: {type: String, default: ''}
    },
    data() {
      return {
        visibleVerticallyCenteredScrollableDemo: false,
      }
    },
    setup(props){
      const store = useStore();
      watch(props,(val) => {
        console.log(val.eventid);
      })
      return {
        store
      }
    },
    mounted() {
      const store = useStore();
      watch(this.visibleVerticallyCenteredScrollableDemo, (val) => {
        if(!val){store.dispatch('closeModalMatch')}
      })
    },
  }
</script>